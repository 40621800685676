import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Section from 'components/Section';
import Button from 'components/Button';
import Link from 'components/Link';
import useSiteMetadata from 'hooks/useSiteMetadata';

export default function AboutPage() {
  const { t } = useTranslation();

  const site = useSiteMetadata();
  const title = t('about:meta.title');

  return (
    <>
      <SEO
        title={`${title} - ${site.title}`}
        description={t('about:meta.desc', { site: site.title })}
        keywords={title}
      />

      <Section>
        <div className="max-w-3xl mx-auto">
          <div className="text-base">
            <h1 className="leading-6 text-blue-600 font-semibold tracking-wide uppercase">
              {t('about:about_site', { site: site.title })}
            </h1>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {t('common:short_desc')}
            </h3>
            <div className="flex mt-8 space-x-4">
              <Button as={Link} href="/downloader" color="secondary">
                {t('common:dl_online')}
              </Button>
              <Button as={Link} href="/converter">
                {t('common:conv_online')}
              </Button>
            </div>

            <p className="mt-8 text-lg text-gray-500">
              {t('about:short_desc', { site: site.title })}
            </p>
            <div className="mt-5 prose prose-blue text-gray-500">
              <ul>
                <li>
                  <Link href="/vimeo-downloader">Vimeo</Link>
                </li>
                <li>
                  <Link href="/dailymotion-downloader">Dailymotion</Link>
                </li>
                <li>
                  <Link href="/twitter-downloader">Twitter</Link>
                </li>
                <li>
                  <Link href="/facebook-downloader">Facebook</Link>
                </li>
                <li>
                  <Link href="/instagram-downloader">Instagram</Link>
                </li>
                <li>
                  <Link href="/reddit-downloader">Reddit</Link>
                </li>
                <li>
                  <Trans
                    i18nKey="about:many_sites"
                    components={[<Link href="/sites" />]}
                  />
                </li>
              </ul>
              <p>{t('about:no_soft')}</p>
              <p>{t('about:enter_link')}</p>

              <h3>{t('about:features')}</h3>
              <ul>
                <li>
                  <Trans
                    i18nKey="about:f1"
                    components={[<Link href="/sites" />]}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey="about:f2"
                    components={[<Link href="/converter" />]}
                  />
                </li>
                <li>{t('about:f3')}</li>
                <li>{t('about:f4')}</li>
                <li>{t('about:f5')}</li>
                <li>{t('about:f6')}</li>
              </ul>

              <h3>{t('about:ads')}</h3>
              <p>
                <Trans
                  i18nKey="about:ads_desc"
                  components={[<Link href="/contact" />]}
                />
              </p>

              <h3>{t('about:soft_rec')}</h3>
              <p>{t('about:soft_rec_desc')}</p>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "about", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
